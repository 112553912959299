import { Widget } from "@typeform/embed-react";

export const Contact = () => {
  return (
    <div className="absolute flex flex-col w-full h-full items-center justify-center bg-brand-blue-900">
      <h1 className="heading-title">Contact me</h1>
      <Widget id="baJ2fT8Y" className="sm:w-1/2 h-1/2 mt-4 " chat />
    </div>
  );
};
