import { Link } from "react-router-dom";

export const Contact = () => {
  return (
    <div className="snap-child">
      <div>
        <h1 className="text-2xl  text-brand-blue-100">NEED AN ILLUSTRATOR?</h1>
        <button className="button-primary mt-4">
          <Link to="contact">Adventure awaits!</Link>
        </button>
      </div>
    </div>
  );
};
