import {
  LeftHero,
  RightHero,
  Intro,
  Services,
  Contact,
} from "../../components";

export const Main = () => {
  return (
    <div className="flex flex-col sm:flex-row w-screen h-screen overflow-hidden">
      <LeftHero />
      <div className="w-full sm:w-1/2  bg-brand-blue-900 snap-parent">
        <RightHero />
        <Intro />
        <Services />
        <Contact />
      </div>
    </div>
  );
};
