export const Intro = () => {
  return (
    <div className="snap-child">
      <div className="lg:p-32 p-16 text-brand-blue-100">
        <h1>MAGIC SPARKS MY PASSION</h1>
        <p className=" text-sm sm:text-2xl mt-4">
          Hi! My name is Azriff Azmi and my brand’s mission, <b>BIFFDRAWSART</b>
          , is to help fantasy lovers and alike create awesome character concept
          art. I am very fond of drawing magical characters, thanks to years of
          role playing as mages in DnD campaigns and other RPGs (but I would
          love to take any of your characters to life!)
          <br />
          <br />
          My art style will bring a fresh perspective to your creation, and
          infuse your role playing campaigns with more personality.
        </p>
        <h1 className=" mt-6 ">AZRIFF AZMI</h1>
      </div>
    </div>
  );
};
