import { useState } from "react";
import { baseUrl } from "../enums";
import { filters } from "../enums";

const getPhotos = (currentFilter) => {
  const filter = filters.find((key) => {
    return currentFilter === key.filter;
  });

  return filter.files;
};

export const PhotoGallery = ({ filter }) => {
  const [photos] = useState(getPhotos(filter));

  return (
    <>
      <h2>{filter.toUpperCase()}</h2>

      <div
        className={`w-full flex flex-col lg:flex-row  justify-center gap-10 pt-5`}
      >
        {photos &&
          photos.map((fileName, key) => {
            const photo = {
              src: `${baseUrl}${filter}/${fileName}`,
              title: fileName,
            };

            return (
              <a
                key={key}
                href={photo.src}
                target="_blank"
                rel="noreferrer"
                className={
                  photos.length > 3
                    ? `lg:w-1/${photos.length + 1} `
                    : `lg:w-1/4  lg:flex lg:flex-grow-0`
                }

                // className={`w-1/${photos.length + 1} `}
              >
                <img
                  src={photo.src}
                  className="w-full object-scale-down"
                  alt={photo.title}
                ></img>
              </a>
            );
          })}
      </div>
    </>
  );
};

// export const PhotoGallery = ({ filter }) => {
//   const [photos, setPhotos] = useState([]);

//   const [currentImage, setCurrentImage] = useState(0);
//   const [viewerIsOpen, setViewerIsOpen] = useState(false);

//   useEffect(() => {
//     const photoList = getPhotos(filter);
//     setPhotos(photoList);
//   }, []);

//   const openLightbox = useCallback((event, { photo, index }) => {
//     setCurrentImage(index);
//     setViewerIsOpen(true);
//   }, []);

//   const closeLightbox = () => {
//     setCurrentImage(0);
//     setViewerIsOpen(false);
//   };

//   return (
//     <>
//       <div className="overflow-x-scroll">
//         <Gallery
//           photos={photos}
//           onClick={openLightbox}
//           direction="row"
//           margin={20}
//         />
//       </div>
//       {viewerIsOpen && (
//         <Lightbox
//           mainSrc={photos[currentImage].src}
//           nextSrc={photos[(currentImage + 1) % photos.length].src}
//           prevSrc={
//             photos[(currentImage + photos.length - 1) % photos.length].src
//           }
//           onCloseRequest={() => closeLightbox()}
//           onMovePrevRequest={() =>
//             setCurrentImage((currentImage + photos.length - 1) % photos.length)
//           }
//           onMoveNextRequest={() =>
//             setCurrentImage((currentImage + photos.length + 1) % photos.length)
//           }
//         />
//       )}
//     </>
//   );
// };
