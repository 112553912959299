import { SVGIcon } from "./SVGIcons";
import { Link } from "react-router-dom";

export const Navbar = () => {
  const routes = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Portfolio",
      link: "portfolio",
    },
    {
      name: "Contact",
      link: "contact",
    },
  ];

  const socials = [
    {
      name: "Instagram",
      icon: <SVGIcon icon="instagram" />,
      link: "https://www.instagram.com/biffdrawsart/",
    },
    {
      name: "Artstation",
      icon: <SVGIcon icon="artstation" />,
      link: "https://www.artstation.com/biffazriff",
    },
    {
      name: "Kofi",
      icon: <SVGIcon icon="kofi" />,
      link: "https://ko-fi.com/biffdrawsart",
    },
    {
      name: "Twitter",
      icon: <SVGIcon icon="twitter" />,
      link: "https://twitter.com/biffdrawsart",
    },
  ];

  return (
    <>
      {/* Display on mobile screens */}
      <div className="sm:hidden absolute z-50 w-full flex flex-col justify-between px-8 py-4 text-brand-blue-100 overflow-hidden">
        <div className="flex place-items-center justify-between ">
          <div className="font-pixel text-xl text-brand-blue-100  h-full">
            AZRIFF AZMI
          </div>
          <div className="flex flex-row  ">
            {socials.map((social, key) => {
              return (
                <div className="px-2 fill-brand-blue-900  w-9 h-9 " key={key}>
                  <a href={social.link} target="_blank" rel="noreferrer">
                    {social.icon}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-row">
          <div className="font-titillium flex flex-row text-md font-semibold  items-center">
            {routes.map((route, key) => {
              return (
                <div className="pr-3" key={key}>
                  <Link to={route.link}>{route.name}</Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Display on larger screens */}
      <div className="hidden absolute z-50 w-full sm:flex flex-row justify-between px-8 py-4 text-brand-blue-100 overflow-hidden">
        <div className=" font-pixel text-xl text-brand-blue-100">
          AZRIFF AZMI
        </div>
        <div className="flex flex-row">
          <div className="font-titillium flex flex-row text-xl font-semibold  items-center">
            {routes.map((route, key) => {
              return (
                <div className="px-3 " key={key}>
                  <Link to={route.link}>{route.name}</Link>
                </div>
              );
            })}
          </div>
          <div className="flex flex-row  ">
            {socials.map((social, key) => {
              return (
                <div className="px-3 fill-brand-blue-900  w-12 h-12" key={key}>
                  <a href={social.link} target="_blank" rel="noreferrer">
                    {social.icon}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
