import { Link } from "react-router-dom";
import LeftHeroImg from "../assets/images/LeftHero.png";

export const RightHero = () => {
  return (
    <div className="snap-child font-titillium text-brand-blue-100">
      <div className="text-left lg:px-36 px-12 flex flex-col flex-start items-start ">
        <img
          className="sm:hidden w-full h-1/2 object-cover mb-4"
          src={LeftHeroImg}
          alt="Hero"
        ></img>
        <h1 className="heading-title">CONCEPT ARTIST</h1>
        <div className="text-sm sm:text-2xl mt-4">
          Bring your characters to life, with fast results and in any resolution
          you need. Don’t limit yourself, let your imagination run free!
        </div>
        <button className=" button-primary mt-6">
          <Link to="contact">CONTACT</Link>
        </button>
      </div>
    </div>
  );
};
