import LeftHeroImg from "../assets/images/LeftHero.png";

export const LeftHero = () => {
  return (
    <div className="hidden  sm:flex w-1/2 bg-brand-blue-100 ">
      <img
        className="w-full h-full object-cover"
        src={LeftHeroImg}
        alt="Hero"
      ></img>
    </div>
  );
};
