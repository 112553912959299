export const Services = () => {
  return (
    <div className="snap-child">
      <div className="text-center text-brand-blue-100">
        <h1>Services:</h1>
        <ul className="mt-8">
          <h1 className="text-2xl mt-2">DRAWINGS</h1>
          <h1 className="text-2xl mt-2">ILLUSTRATIONS</h1>
          <h1 className="text-2xl mt-2">CONCEPT CHARACTERS</h1>
        </ul>
      </div>
    </div>
  );
};
