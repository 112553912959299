import { PhotoGallery } from "../../components";

export const Portfolio = () => {
  return (
    <div className="absolute flex flex-col w-full h-full bg-brand-blue-900">
      <h1 className="w-full h-1/3 pt-28 pb-10 sm:p-20  text-center heading-title">
        PORTFOLIO
      </h1>
      {/* <div className="w-full h-1/3 p-20">
        <img
          className="object-contain w-full h-full"
          src={LeftHeroImg}
          alt="Hero"
        ></img>
      </div> */}
      <div className="photo-main">
        <PhotoGallery filter="coloured" />
      </div>
      <div className="photo-alt">
        <PhotoGallery filter="illustration" />
      </div>
      <div className="photo-main">
        <PhotoGallery filter="line-art" />
      </div>
    </div>
  );
};
