export const baseUrl =
  "https://biff-portfolio.s3.ap-southeast-1.amazonaws.com/";

export const filters = [
  {
    filter: "coloured",
    files: [
      "01241221_Commission_-_Human_Warrior.png",
      "02241221_Commission_(promo)_-_Female_Cleric_Nun.png",
      "06011221_Commission_-_Purple_Tiefling_.png",
      "Blue_Mage_Lv6.png",
      "Blue_Sorceress_Lv16.png",
    ],
  },
  {
    filter: "illustration",
    files: [
      "IMG_2797.jpg",
      "IMG_2798.jpg",
      "Naedra_The_Claw,_Orc_Beastmaster_.png",
    ],
  },
  {
    filter: "line-art",
    files: [
      "06021121_Commission_-_Ruby_ALexia.png",
      "06021121_Commission_-_Luna_Finscale_.png",
      "06021121_Commission_-_Everly_Ayers.png",
      "02061121_Commission_-_Therein_Varkaris_.png",
      "_06021121_Commission_-_Madcap_Jack_.png",
    ],
  },
];
