const ArtStationIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <defs id="SvgjsDefs1002"></defs>
      <g>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 48 48"
        >
          <path
            fill="currentColor"
            d="M2.141 34l3.771 6.519.001.001C6.656 41.991 8.18 43 9.94 43l.003 0 0 0h25.03l-5.194-9H2.141zM45.859 34.341c0-.872-.257-1.683-.697-2.364L30.977 7.319C30.245 5.94 28.794 5 27.124 5h-7.496l21.91 37.962 3.454-5.982C45.673 35.835 45.859 35.328 45.859 34.341zM25.838 28L16.045 11.038 6.252 28z"
          ></path>
        </svg>
      </g>
    </svg>
  );
};

const InstagramIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <defs id="SvgjsDefs1018"></defs>
      <g>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 48 48"
        >
          <path
            d="M 16 6 C 10.476562 6 6 10.476562 6 16 L 6 32 C 6 37.523438 10.476562 42 16 42 L 32 42 C 37.523438 42 42 37.523438 42 32 L 42 16 C 42 10.476562 37.523438 6 32 6 Z M 36 10 C 37.105469 10 38 10.894531 38 12 C 38 13.105469 37.105469 14 36 14 C 34.894531 14 34 13.105469 34 12 C 34 10.894531 34.894531 10 36 10 Z M 24 14 C 29.523438 14 34 18.476562 34 24 C 34 29.523438 29.523438 34 24 34 C 18.476562 34 14 29.523438 14 24 C 14 18.476562 18.476562 14 24 14 Z M 24 18 C 20.6875 18 18 20.6875 18 24 C 18 27.3125 20.6875 30 24 30 C 27.3125 30 30 27.3125 30 24 C 30 20.6875 27.3125 18 24 18 Z M 24 18 "
            fill="currentColor"
          ></path>
        </svg>
      </g>
    </svg>
  );
};

const TwitterIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <defs></defs>
      <g>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 50 50"
        >
          <path
            d="M 50.0625 10.4375 C 48.214844 11.257813 46.234375 11.808594 44.152344 12.058594 C 46.277344 10.785156 47.910156 8.769531 48.675781 6.371094 C 46.691406 7.546875 44.484375 8.402344 42.144531 8.863281 C 40.269531 6.863281 37.597656 5.617188 34.640625 5.617188 C 28.960938 5.617188 24.355469 10.21875 24.355469 15.898438 C 24.355469 16.703125 24.449219 17.488281 24.625 18.242188 C 16.078125 17.8125 8.503906 13.71875 3.429688 7.496094 C 2.542969 9.019531 2.039063 10.785156 2.039063 12.667969 C 2.039063 16.234375 3.851563 19.382813 6.613281 21.230469 C 4.925781 21.175781 3.339844 20.710938 1.953125 19.941406 C 1.953125 19.984375 1.953125 20.027344 1.953125 20.070313 C 1.953125 25.054688 5.5 29.207031 10.199219 30.15625 C 9.339844 30.390625 8.429688 30.515625 7.492188 30.515625 C 6.828125 30.515625 6.183594 30.453125 5.554688 30.328125 C 6.867188 34.410156 10.664063 37.390625 15.160156 37.472656 C 11.644531 40.230469 7.210938 41.871094 2.390625 41.871094 C 1.558594 41.871094 0.742188 41.824219 -0.0585938 41.726563 C 4.488281 44.648438 9.894531 46.347656 15.703125 46.347656 C 34.617188 46.347656 44.960938 30.679688 44.960938 17.09375 C 44.960938 16.648438 44.949219 16.199219 44.933594 15.761719 C 46.941406 14.3125 48.683594 12.5 50.0625 10.4375 Z"
            fill="currentColor"
          ></path>
        </svg>
      </g>
    </svg>
  );
};

const KofiIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <defs></defs>
      <g transform="matrix(1,0,0,1,0,0)">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 64 64"
        >
          <g transform="translate(0 -51.106) scale(1.9656)" fill="#000000">
            <g fill="none" transform="translate(0 26)">
              <path
                fill="currentColor"
                d="m 22.257931,8.8 h 1.607617 C 26.966324,8.8 29.48,11.313676 29.48,14.414452 v 0.330015 c 0,3.100776 -2.513676,5.614452 -5.614452,5.614452 h -1.607617 v 1.689795 c 0,1.431128 -1.160158,2.591286 -2.591286,2.591286 H 7.4312857 C 6.0001581,24.64 4.84,23.479842 4.84,22.048714 V 10.095643 C 4.84,9.3800791 5.4200791,8.8 6.1356429,8.8 Z m 0,2.996757 v 5.565405 h 1.465573 c 1.536844,0 2.782703,-1.245858 2.782703,-2.782702 0,-1.536845 -1.245859,-2.782703 -2.782703,-2.782703 z"
              ></path>
            </g>
            <path
              fill="#ff5e5b"
              d="M 8.36,5.2768458 C 8.7602942,4.1056152 9.7089414,3.52 11.205941,3.52 c 2.2455,0 3.077965,2.7936503 1.900934,4.62 C 12.322188,9.3575665 10.739896,10.897567 8.36,12.76 5.9801039,10.897567 4.3978123,9.3575665 3.613125,8.14 2.4360941,6.3136503 3.2685587,3.52 5.5140587,3.52 7.0110586,3.52 7.9597058,4.1056152 8.36,5.2768458 Z"
              transform="translate(4.84 34.8)"
            ></path>
          </g>
        </svg>
      </g>
    </svg>
  );
};

export const SVGIcon = ({ icon }) => {
  switch (icon) {
    case "artstation":
      return <ArtStationIcon />;
    case "instagram":
      return <InstagramIcon />;
    case "kofi":
      return <KofiIcon />;
    case "twitter":
      return <TwitterIcon />;
    default:
      return;
  }
};
